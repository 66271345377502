import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../index.css';
import logo from '../images/CTRL-Logo-Color-NBG-CC-Photoroom-1.png';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";
import { slide as Menu } from 'react-burger-menu';
import Modal from 'react-modal';

function MainPage(props) {
    const navigate = useNavigate();
    const [log, setLog] = useState([]);
    const [carwash1Status, setCarwash1Status] = useState('IDLE');
    const [carwash2Status, setCarwash2Status] = useState('IDLE');
    const { setToken } = useAuth();
    const logEndRef = useRef(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedCarwash, setSelectedCarwash] = useState(null); // State to store selected carwash

    function openModal(carwash) {
        if ([carwash1Status, carwash2Status][carwash - 1] === 'IDLE') {
            setSelectedCarwash(carwash); // Store the selected carwash
            setIsOpen(true); // Show the modal
        }
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleLogout = () => {
        setToken();
        navigate('/login');
    };

    const handleButtonClick = async (carwash) => {
        if ([carwash1Status, carwash2Status][carwash - 1] === 'OFFLINE' || [carwash1Status, carwash2Status][carwash - 1] === 'BUSY') return;
        try {
            const response = await axios.post('https://carwash.ctrl.zone/startcarwash', { carwash_id: carwash });
            console.log(response);
            if (response.status === 200) {
                var d = new Date();
                const formattedDate = formatDate(d);
                const newLog = [...log, `Carwash ${carwash} Started: ${formattedDate}`];
                setLog(newLog);
                if (carwash === 1) {
                    setCarwash1Status('BUSY');
                } else if (carwash === 2) {
                    setCarwash2Status('BUSY');
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setCarwash1Status('OFFLINE');
            setCarwash2Status('OFFLINE');
        }
        closeModal(); // Close the modal after handling the button click
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    };

    useEffect(() => {
        logEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [log]);

    useEffect(() => {
        let isMounted = true; // Flag to indicate if the component is mounted
    
        const checkStatus = async () => {
            try {
                const response = await axios.get('https://carwash.ctrl.zone/status');
                if (response.status === 200) {
                    const server_online = response.data.server_online;
                    if (!server_online) {
                        if (isMounted) {
                            setCarwash1Status('OFFLINE');
                            setCarwash2Status('OFFLINE');
                        }
                        return;
                    }
                    const carwashes = response.data.carwash_status;
                    if (isMounted) {
                        carwashes.forEach(carwash => {
                            if (carwash.carwash_id === 1) {
                                setCarwash1Status(carwash.carwash_status);
                            } else if (carwash.carwash_id === 2) {
                                setCarwash2Status(carwash.carwash_status);
                            }
                        });
                    }
                }
            } catch (error) {
                console.error("Error fetching status:", error);
            }
        };
    
        const interval = setInterval(() => {
            checkStatus();
        }, 3000); // Check status every 3 seconds
    
        return () => {
            isMounted = false; // Set the flag to false when the component unmounts
            clearInterval(interval); // Cleanup interval on component unmount
        };
    }, []);

    const getButtonStyle = (carwashStatus) => {
        switch (carwashStatus) {
            case 'IDLE':
                return { backgroundColor: 'aquamarine' };
            case 'OFFLINE':
                return { backgroundColor: 'lightgrey' };
            case 'BUSY':
                return { backgroundColor: 'tomato' };
            default:
                return { backgroundColor: 'lightgrey' };
        }
    };

    return (
        <div className='main-page'>
            <Menu right outerContainerId='main-page'>
                <Link id="home" className="menu-item">Home</Link>
                <Link id="pricing" className="menu-item" to="/pricing">Pricing</Link>
                <Link id="coppertree" className="menu-item" to="https://coppertree.co.nz" target="_blank">Transactions</Link>
                <Link id="logout" className="menu-item-logout" onClick={handleLogout}>Logout</Link>
            </Menu>
            <div className="outer-container">
                <div className="container">
                    <div className="wrapper">
                        <Modal
                            className={'modal'}
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Confirm Action"
                        >
                            <h2>Confirm Action</h2>
                            <div>Are you sure you want to start Carwash {selectedCarwash}?</div>
                            <div className="modal-button-container">
                                <button className="cancel-button" onClick={closeModal}>Cancel</button>
                                <button className="confirm-button" onClick={() => handleButtonClick(selectedCarwash)}>Confirm</button>
                            </div>
                        </Modal>
                        <div className="title">
                            <img className='logo' src={logo} alt="logo"/>
                        </div>

                        <div className="button-container">
                            <div className="button-1">
                                <button 
                                    className="carwash-button" 
                                    onClick={() => openModal(1)}
                                    style={getButtonStyle(carwash1Status)}
                                    disabled={carwash1Status === 'OFFLINE' || carwash1Status === 'BUSY'}
                                >
                                    Start<br/>Carwash 1
                                </button>
                                <p className='status-text'>Status: {carwash1Status}</p>
                            </div>
                            <div className="button-2">
                                <button 
                                    className="carwash-button" 
                                    onClick={() => openModal(2)}
                                    style={getButtonStyle(carwash2Status)}
                                    disabled={carwash2Status === 'OFFLINE' || carwash2Status === 'BUSY'}
                                >
                                    Start<br/>Carwash 2
                                </button>
                                <p className='status-text'>Status: {carwash2Status}</p>
                            </div>
                        </div>

                        <p className="log-text">Transaction Log</p>
                        <div className="log-box">
                            {log.map((entry, index) => (
                                <div key={index}>{entry}</div>
                            ))}
                            <div ref={logEndRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default MainPage;