import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../index.css';
import { slide as Menu } from 'react-burger-menu';
import { useAuth } from "../provider/authProvider";
import logo from '../images/CTRL-Logo-Color-NBG-CC-Photoroom-1.png';
import axios from 'axios';
import Modal from 'react-modal';

function PricingPage() {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedCarwash, setSelectedCarwash] = useState(null);
    const [priceChange1, setPriceChange1] = useState(0);
    const [priceChange2, setPriceChange2] = useState(0);
    const [log, setLog] = useState([]);
    const logEndRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [carwash1Price, setCarwash1Price] = useState(() => {
        const savedPrice = localStorage.getItem('carwash1Price');
        return savedPrice ? parseFloat(savedPrice) : 0;
    });
    const [carwash2Price, setCarwash2Price] = useState(() => {
        const savedPrice = localStorage.getItem('carwash2Price');
        return savedPrice ? parseFloat(savedPrice) : 0;
    });
    const [buttonsDisabled, setButtonsDisabled] = useState(false); // New state to manage button disabling

    function openModal(carwash) {
        setSelectedCarwash(carwash);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleLogout = () => {
        setToken();
        navigate('/login');
    };

    const handleButtonClick = async (kora_id, price) => {
        price = Number(price).toFixed(2);
        setPriceChange1(0);
        setPriceChange2(0);
        setLoading(true);
        setButtonsDisabled(true); // Disable buttons when action starts
        try {
            const response = await axios.post('https://carwash.ctrl.zone/price', { kora_id: kora_id, price: price });
            console.log(response);
            var d = new Date();
            const formattedDate = formatDate(d);
            const newLog = [...log, response.status === 200 ? `Carwash ${kora_id} Price Changed: ${formattedDate}` : `Failed to Change Price: ${formattedDate}`];
            setLog(newLog);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
                setButtonsDisabled(false); // Re-enable buttons after processing
            }, 3000);
        }
        closeModal();
    };

    useEffect(() => {
        let isMounted = true;

        const checkStatus = async () => {
            try {
                const response = await axios.get('https://carwash.ctrl.zone/status');
                if (response.status === 200) {
                    const server_online = response.data.server_online;
                    if (!server_online) {
                        if (isMounted) {
                            setCarwash1Price(0);
                            setCarwash2Price(0);
                        }
                        return;
                    }
                    const carwash_prices = response.data.carwash_prices;
                    if (isMounted) {
                        carwash_prices.forEach(carwash => {
                            if (carwash.kora_id === 1) {
                                const newPrice = Number(carwash.price * 1.15).toFixed(2);
                                const storedPrice = parseFloat(localStorage.getItem('carwash1Price'));
                                if (newPrice !== storedPrice) {
                                    setCarwash1Price(newPrice);
                                    localStorage.setItem('carwash1Price', newPrice);
                                }
                            } else if (carwash.kora_id === 2) {
                                const newPrice = Number(carwash.price * 1.15).toFixed(2);
                                const storedPrice = parseFloat(localStorage.getItem('carwash2Price'));
                                if (newPrice !== storedPrice) {
                                    setCarwash2Price(newPrice);
                                    localStorage.setItem('carwash2Price', newPrice);
                                }
                            }
                        });
                    }
                }
            } catch (error) {
                console.error("Error fetching status:", error);
            }
        };

        const interval = setInterval(() => {
            checkStatus();
        }, 3000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        logEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [log]);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    };

    return (
        <div className='main-page'>
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-icon"></div>
                </div>
            )}
            <Menu right outerContainerId='main-page'>
                <Link id="home" className="menu-item" to="/">Home</Link>
                <Link id="pricing" className="menu-item">Pricing</Link>
                <Link id="coppertree" className="menu-item" to="https://coppertree.co.nz" target="_blank">Transactions</Link>
                <Link id="logout" className="menu-item-logout" onClick={handleLogout}>Logout</Link>
            </Menu>
            <div className="outer-container">
                <div className="container">
                    <div className="wrapper">
                        <Modal
                            className={'modal'}
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Confirm Action"
                        >
                            <h2>Confirm Action</h2>
                            <div>
                                Are you sure you want to change the price of Carwash {selectedCarwash} to ${selectedCarwash === 1 ? priceChange1 : priceChange2}?
                            </div>
                            <div className="modal-button-container">
                                <button className="cancel-button" onClick={closeModal}>Cancel</button>
                                <button className="confirm-button" onClick={() => handleButtonClick(selectedCarwash, selectedCarwash === 1 ? priceChange1 : priceChange2)}>Confirm</button>
                            </div>
                        </Modal>
                        <div className="title">
                            <img className='logo' src={logo} alt="logo" />
                        </div>
                        <div className="pricing-container">
                            <div className='pricing-inner-container'>
                                <p className='pricing-text'>Carwash 1 Price: ${Number(carwash1Price).toFixed(2)}</p>
                                <div className='pricing-inputs'>
                                    <input
                                        type="number"
                                        value={priceChange1}
                                        onChange={(e) => {
                                            const value = parseFloat(e.target.value);
                                            setPriceChange1(isNaN(value) ? '' : value < 0 ? 0 : value); // Set to empty string if NaN
                                        }}
                                    />
                                    <button 
                                        onClick={() => openModal(1)} 
                                        disabled={buttonsDisabled || priceChange1 === ''} // Disable if empty
                                        className={buttonsDisabled ? 'disabled-button' : ''}
                                    >
                                        Change Price
                                    </button>
                                </div>
                            </div>
                            <div className='pricing-inner-container'>
                                <p className='pricing-text'>Carwash 2 Price: ${Number(carwash2Price).toFixed(2)}</p>
                                <div className='pricing-inputs'>
                                    <input
                                        type="number"
                                        value={priceChange2}
                                        onChange={(e) => {
                                            const value = parseFloat(e.target.value);
                                            setPriceChange2(isNaN(value) ? '' : value < 0 ? 0 : value); // Set to empty string if NaN
                                        }}
                                    />
                                    <button 
                                        onClick={() => openModal(2)} 
                                        disabled={buttonsDisabled || priceChange2 === ''} // Disable if empty
                                        className={buttonsDisabled ? 'disabled-button' : ''}
                                    >
                                        Change Price
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p className="log-text">Transaction Log</p>
                        <div className="log-box">
                            {log.map((entry, index) => (
                                <div key={index}>{entry}</div>
                            ))}
                            <div ref={logEndRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PricingPage;
