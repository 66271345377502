// Import React and necessary hooks
import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import '../index.css'; // Import CSS file
import logo from '../images/CTRL-Logo-Color-NBG-CC-Photoroom-1.png';
import { useAuth } from "../provider/authProvider";

function LoginPage(props) {
    // State variables for username, password, and error message
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { setToken } = useAuth();
    const navigate = useNavigate();

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Clear previous error messages
            setErrorMessage('');

            // Send login request to server
            const response = await axios.post('https://carwash.ctrl.zone/login', { username, password });

            // If login successful, redirect to MainPage
            if (response.status === 200) {
                setToken('9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08');
                navigate('/');
            }
            
        } catch (error) {
            console.error('Error:', error);
            // If login failed, display error message
            setErrorMessage('Your Username or Password are incorrect.');
        }
    };

    // JSX structure for login form
    return (
        <div className="login-outer-container">
            <div className="container">
                <div className="wrapper">

                    <div className="title">
                        <img className='logo' src={logo} alt="logo"/>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <input 
                                type="text" 
                                id="username"
                                placeholder="Username" 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required 
                            />
                        </div>
                        <div className="row">
                            <input 
                                type="password" 
                                id="username"
                                placeholder="Password" 
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required 
                            />
                        </div>
                        <div className="row button">
                            <input type="submit" value="Login" />
                            {errorMessage && <p className='errorMessage'>{errorMessage}</p>}
                        </div>
                    </form>
                </div>
            </div>
        </div>  
    );
}

export default LoginPage;
